.tableContainer {
    grid-row: 2;
    height: 100%;
    width: 95%;
    margin: 0 auto;
}

table {
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid black;
}

tbody {
    text-align: center;
}

th, td {
    border: 1px solid black;
    padding: 5px;
    vertical-align: center;
}

table caption {
    color: #FFFFFF;
    font-family: NeonTubes;
}