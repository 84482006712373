h1, h2, h3 {
    text-align: center;
    font-family: NeonTubes;
}

h1{
    font-size: 4em;
    text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #D63232 0px 0px 20px, #D63232 0px 0px 30px, #D63232 0px 0px 40px, #D63232 0px 0px 50px, #D63232 0px 0px 75px;
}

h2 {
    font-size: 2em;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px royalblue, 0 0 30px royalblue, 0 0 40px royalblue, 0 0 55px royalblue, 0 0 75px royalblue;
}

h3 {
font-size: 1.3em;
}

#title1 {
    display: inline;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px darkred, 0 0 30px darkred, 0 0 40px darkred, 0 0 55px darkred, 0 0 75px darkred;
}

#title2 {
    display: inline;
    font-style: revert;
    font-weight: bold;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px royalblue, 0 0 30px royalblue, 0 0 40px royalblue, 0 0 55px royalblue, 0 0 75px royalblue;
}

label {
    font-family: 'NeonTubes';
    font-weight: bold;
}