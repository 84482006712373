.container {
  margin: 5% 3%;
}

.vertical-table {
  width: 100%;
  margin-bottom: 1.5em;
  border-spacing: 0;
}

.vertical-table thead {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.vertical-table thead th {
  background-color: #1d96b2;
  border: 1px solid #1d96b2;
  font-weight: normal;
  text-align: center;
  color: white;
}
.vertical-table thead th:first-of-type {
  text-align: left;
}
.vertical-table tbody,
.vertical-table tr,
.vertical-table th,
.vertical-table td {
  display: block;
  padding: 0;
  text-align: left;
  white-space: normal;
}
.vertical-table th,
.vertical-table td {
  padding: .5em;
  vertical-align: middle;
}
.vertical-table caption {
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
.vertical-table tfoot {
  font-size: .8em;
  font-style: italic;
}
.vertical-table tbody tr {
  margin-bottom: 1em;
}
.vertical-table tbody tr:last-of-type {
  margin-bottom: 0;
}
.vertical-table tbody th[scope="row"] {
  background-color: #1d96b2;
  color: white;
}
.vertical-table tbody td {
  text-align: right;
}
.vertical-table tbody td[data-type=currency] {
  text-align: right;
}
.vertical-table tbody td[data-title]:before {
  content: attr(data-title);
  float: left;
  font-size: .8em;
  color: rgba(94, 93, 82, 0.75);
}
