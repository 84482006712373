@import "./css/vertical-table.css";
@import "./css/responsive-table.css";
@import "./css/loader.css";
@import "./css/top-left-text-area.css";
@import "./css/table.css";
@import "./css/details.css";
@font-face {
    font-family: "NeonTubes";
    src: url("static/NeonTubes2.otf") format("opentype");
}

html {
    text-align: center;
    color: #FFFFFF;
    font-family: 'helvetica neue', helvetica, arial, sans-serif;
    background: #121212;
    height: 100%;
    padding: 0;
}


summary {
    font-family: 'NeonTubes';
}

button {
    box-shadow: 0px 10px 14px -7px #276873;
    background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
    background-color:#599bb3;
    border-radius:8px;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:15px;
    font-weight:bold;
    padding: 2px 5px;
    text-decoration:none;
    text-shadow:0px 1px 0px #3d768a;
    vertical-align: center;
}
button:hover {
    background:linear-gradient(to bottom, #408c99 5%, #599bb3 100%);
    background-color:#408c99;
}
button:active {
    position:relative;
    top:1px;
}

input{
    -moz-box-shadow: inset -3px -3px 10px #eee;
    -webkit-box-shadow: inset -3px -3px 10px #eee;
    box-shadow: inset -3px -3px 10px #eee;
    border-radius: 15px;
}

canvas {
    height: 80vh;
}

#canvasContainer {
    padding-left: 4em;
    padding-right: 4em;
}


.chartCanvas {
    position: relative;
}