@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");

details {
    color: white;
    cursor: pointer;
    font-family: "roboto", arial;
    font-weight: 300;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
}
details summary {
    background: linear-gradient(#333333, #1a1a1a);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding: 1em;
    font-size: 1em;
}
details summary:focus {
    outline: none;
}
details p {
    padding: 1em;
    font-size: 1em;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.01);
}
details p:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.02);
}
details p:nth-last-child(1) {
    border-bottom: 0.3vh solid #333333;
}
